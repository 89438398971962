.tabbar {
  width: 100%;

  padding: 20px 30px;

  position: fixed;
  left: 0;
  bottom: 0;

  display: flex;
  justify-content: space-between;

  box-shadow: 0 -4px 27px #000000;

  z-index: 100;

  background: #121212;

  .menu {
    display: flex;
    align-items: center;
    gap: 15px;

    &Item {
      width: 120px;

      display: flex;
      align-items: center;
      gap: 8px;

      font-family: 'TT Commons', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: -0.02em;

      //cursor: pointer;

      transition: .3s ease;

      will-change: transform;

      //&:hover {
      //  transform: scale(1.05);
      //}

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .action {
    width: 70px;
    height: 70px;

    position: absolute;
    right: 30px;
    bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    //cursor: pointer;
    transition: .3s ease;

    border-radius: 50%;

    box-shadow: 0 0 24px rgba(255, 255, 255, 0.6);

    background: #ffffff;

    //&:hover {
    //  transform: scale(1.1) rotate(360deg);
    //}
  }
}
