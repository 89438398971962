@import "fonts/ttcommons";
@import "fonts/tttravels";

#root {
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  --color-up: #1BE074;
  --color-down: #FE4546;
}

* {
  box-sizing: border-box;

  backface-visibility: hidden;
}

body {
  overflow-x: hidden;

  color: #ffffff;
  background: #000000;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

a {
  text-decoration: none;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//html {
//  font-size: 16px;
//
//  @media screen and (min-width: 1920px) {
//    font-size: 16px;
//  }
//
//  @media screen and (min-width: 2550px) {
//    font-size: 20px;
//  }
//
//  @media screen and (max-width: 1800px) {
//    font-size: 14px;
//  }
//
//  @media screen and (min-width: 3830px) {
//    font-size: 28px;
//  }
//
//  @media screen and (max-width: 1600px) {
//    font-size: 12px;
//  }
//
//  @media screen and (max-width: 1200px) {
//    font-size: 10px;
//  }
//}
