.listItem {
  padding: 12px 20px 12px 24px;

  display: flex;
  align-items: center;
  gap: 24px;

  border-radius: 12px;

  cursor: pointer;
  transition: .3s ease;

  background: #1B1B1B;

  &:hover {
    transform: scale(1.03);
  }

  .logo {
    width: 38px;
    height: 38px;

    border-radius: 50%;
  }

  .info {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 2px;

    &Caption {
      font-family: 'TT Commons', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: -0.02em;

      color: #5A5A5A;
    }

    &Balance {
      display: flex;
      justify-content: space-between;
      gap: 8px;

      font-family: 'TT Commons', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;

      .status {
        display: flex;
        align-items: center;
        gap: 4px;

        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;

        &Up {
          color: var(--color-up);
        }

        &Down {
          color: var(--color-down);
        }
      }
    }

    &Description {
      display: flex;
      gap: 4px;

      font-family: 'TT Commons', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      color: #9899A0;
    }
  }
}
