.header {
  width: 100%;
  height: 85px;

  position: fixed;
  top: 0;

  z-index: 1000;

  overflow: hidden;
  transition: .3s ease-in-out;

  border-bottom: 1px solid #292929;

  background: #000000;

  .menu {
    position: relative;

    padding: 22px 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    z-index: 110;

    .title {
      cursor: pointer;

      font-family: 'TT Travels', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 41px;
      letter-spacing: -0.08em;

      font-feature-settings: 'ss02' on;

      color: #ffffff;

      transition: transform .3s ease;

      &:hover {
        transform: scale(1.05);
      }
    }

    .burger {
      width: 34px;
      height: 17px;

      position: relative;

      cursor: pointer;

      .line {
        width: 100%;
        height: 3px;

        position: absolute;

        transition: .5s ease;

        background: #ffffff;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 7px;
        }

        &:nth-child(3) {
          width: 50%;

          top: 14px;
          right: 0;
        }
      }
    }
  }

  &Active {
    height: 100vh;

    background: #000000;

    .menu {
      .burger {
        .line {
          &:nth-child(1) {
            top: 7px;

            transform: rotate(45deg);
          }

          &:nth-child(2) {
            transform: rotate(-45deg);
          }

          &:nth-child(3) {
            width: 100%;

            top: 7px;

            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &Inner {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'TT Travels', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: -0.08em;

    opacity: 0;
    transition: .3s ease-in-out;

    z-index: 105;

    &Active {
      opacity: 1;
    }
  }
}
