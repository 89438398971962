.page {
  display: flex;
  flex-direction: column;

  flex: 1;

  padding-top: 85px;
  padding-bottom: 100px;

  .balance {
    padding: 21px 30px;

    display: flex;
    justify-content: space-between;

    background: #121212;

    .info {
      &Title {
        display: flex;
        gap: 8px;

        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 34px;

        .status {
          margin-bottom: 10px;

          display: flex;
          align-items: center;
          gap: 4px;

          font-family: 'TT Commons', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 23px;

          color: #1BE074;
        }
      }

      &Description {
        margin-top: 8px;

        display: flex;
        align-items: center;
        gap: 26px;

        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.02em;

        color: #5A5A5A;

        div {
          position: relative;
        }

        div:not(:last-child)::after {
          width: 2px;
          height: 60%;

          position: absolute;
          top: 2px;
          right: -15px;

          content: "";

          background: #292929;
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 10px;

      &Item {
        width: 48px;
        height: 48px;

        display: flex;
        align-items: center;
        justify-content: center;

        //cursor: pointer;

        border: 1px solid #292929;
        border-radius: 50%;

        img {
          transition: .3s ease;
          will-change: transform;

          //&:hover {
          //  transform: scale(1.1);
          //}
        }
      }
    }
  }

  .period {
    padding: 20px 30px;

    display: flex;
    flex-direction: column;

    overflow: hidden;

    border-top: 1px solid #292929;

    background: rgba(#1b1b1b, .9);

    .info {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      user-select: none;

      &Caption {
        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.02em;

        color: #5A5A5A;
      }

      &Title {
        margin-top: 4px;

        display: flex;
        align-items: center;
        gap: 6px;

        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;

        cursor: pointer;

        transition: .3s ease;

        img {
          margin-bottom: 4px;
        }

        &:hover {
          opacity: .7;
        }
      }
    }

    .selection {
      max-height: 0;

      overflow: hidden;

      transition: .3s max-height ease;

      &Item {
        padding: 10px 0;

        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-size: 18px;
        line-height: 18px;

        opacity: .7;

        cursor: pointer;

        transition: .3s ease;

        &:first-child {
          padding-top: 15px;
        }

        &:hover {
          opacity: 1;
        }
      }

      &Active {
        max-height: 200px;
      }
    }
  }

  .portfolio {
    margin-top: 35px;
    padding: 0 30px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      &Caption {
        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: -0.02em;

        color: #9899A0;
      }

      &Balance {
        margin-top: 8px;

        display: flex;
        gap: 8px;

        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 34px;

        .status {
          margin-bottom: 10px;

          display: flex;
          align-items: center;
          gap: 4px;

          font-family: 'TT Commons', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 23px;

          color: #FE4546;
        }
      }
    }

    .action {
      width: 48px;
      height: 48px;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      border: 1px solid #292929;
      border-radius: 50%;

      &:hover {
        img {
          transform: rotate(360deg);
        }
      }

      img {
        transition: .3s ease;
      }
    }
  }

  .list {
    margin-top: 20px;
    padding: 0 30px;

    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .transfer {
    padding: 20px 30px;

    display: flex;
    flex-direction: column;

    overflow: hidden;

    border-top: 1px solid #292929;

    background: #1A1A1A;

    &Inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .info {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      user-select: none;

      &Caption {
        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: -0.02em;

        color: #5A5A5A;
      }

      &Title {
        margin-top: 4px;

        display: flex;
        align-items: center;
        gap: 6px;

        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;

        cursor: pointer;

        transition: .3s ease;

        img {
          margin-bottom: 4px;
        }

        &:hover {
          opacity: .7;
        }
      }
    }

    .close {
      width: 40px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      transition: .3s ease;

      border-radius: 50%;

      background: #121212;

      &:hover {
        transform: scale(1.1);
      }
    }

    .selection {
      max-height: 0;

      overflow: hidden;

      transition: .3s max-height ease;

      &Item {
        padding: 10px 0;

        font-family: 'TT Commons', sans-serif;
        font-style: normal;
        font-size: 18px;
        line-height: 18px;

        opacity: .7;

        cursor: pointer;

        transition: .3s ease;

        &:first-child {
          padding-top: 15px;
        }

        &:hover {
          opacity: 1;
        }
      }

      &Active {
        max-height: 200px;
      }
    }
  }
}
